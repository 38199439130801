import { Injectable } from '@angular/core';
import { HttpPromiseService } from './http-promise.service';
import { ITalentUpdateJob } from 'src/modules/talent-dashboard/interfaces/talent-job-update.interface';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { ITalentJob } from 'src/modules/talent-dashboard/interfaces/talent-job.interface';
import { IBroadcastRequest } from 'src/modules/company-dashboard/interfaces/broadcast-request.interface';
import { RecurringShiftsTimingComponent } from '../components/recurring-shifts-timing/recurring-shifts-timing.component';
import { ModalController } from '@ionic/angular';
import { SkillsService } from './skills.service';
import { LocalStorageService } from './local-storage.service';
import { IContractorHistory } from 'src/modules/company-dashboard/interfaces/contractorHistory.interface';
import { IContractorShiftStats } from 'src/modules/company-dashboard/interfaces/IContractorShiftStats.interface';
import { IJobState } from 'src/modules/company-dashboard/interfaces/job-state.interface';

@Injectable({ providedIn: 'root' })
export class JobService {
  constructor(
    private httpPromiseService: HttpPromiseService,
    private modalController: ModalController,
    private skillsService: SkillsService,
    private localStorageService: LocalStorageService,
  ) {}

  public getDefaultRates(userId: string) {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/${userId}/defaultJobRates`);
  }

  public isStafferAvailableToAcceptJob(
    jobId: string,
    body: {
      staffer_id: string;
    }
  ) {
    return this.httpPromiseService.httpPutRequest(`/api/jobs/${jobId}/isStafferAvailableToAcceptJob`, body);
  }

  public updateJob(jobId: string, body: ITalentUpdateJob) {
    return this.httpPromiseService.httpPutRequest(`/api/jobs/${jobId}`, body);
  }

  public getAvailableShifts(staffer: IUser) {
    return this.httpPromiseService.httpPutRequest(
      `/api/jobs/availableShifts/${staffer.address.province}?stafferId=${staffer._id}&type=${staffer.type}`,
      { user: staffer }
    );
  }

  public getPlacesWorkedForStaffer(userId: string) {
    return this.httpPromiseService.httpGetRequest(`/api/placesWorked/?staffer=${userId}`);
  }

  public getAvailableShiftsV2(onlyRecurring?: boolean, expertise?: string) {


    return this.httpPromiseService.httpGetRequest(
      `/api/v2/jobs/available-shifts?showRecurringOnly=${onlyRecurring || false}&showMyApplications=false&skills=${expertise || 'Hospitality'}&byJson=true`
    );
  }

  public getClientCalendarShifts(entityId: string, jobStatusFlag: IJobState, endTime: string, startTime?: string, onlyRecurrentJobs?:(boolean|string)) {
    const filled = jobStatusFlag.filled;
    const open = jobStatusFlag.open;
    const partial = jobStatusFlag.partial;
    const completed = jobStatusFlag.completed;
    const ongoing = jobStatusFlag.ongoing;

    let url = `/api/v2/jobs/calendar-shifts?entityId=${entityId}&isFilled=${filled}&open=${open}&partial=${partial}&completed=${completed}&endTime=${endTime}&startTime=${startTime}&onlyRecurrentJobs=${onlyRecurrentJobs}`;

    //Add ongoing present if ongoing shift is selected 
    if(ongoing) {
      url += `&preset=getOngoingCalendarJobPreset`;
    }

    return this.httpPromiseService.httpGetRequest(
      url
    );
  }


  public async getPlacesWorkedWithAvailableShiftsV2(userId: string, onlyRecurring?: boolean, expertise?: string) {

    let [placesWorkedData, availableShiftsData] = await Promise.all([
      this.getPlacesWorkedForStaffer(userId),
      this.getAvailableShiftsV2(onlyRecurring, expertise)
    ]);

    if(!availableShiftsData.results){
      return;
    }
  
    availableShiftsData.results = availableShiftsData.results.map(availableShift => {
      let canApply = false;
      
      if(availableShift.isTrainingShift || 
        !availableShift.trainingRequired || 
        placesWorkedData.findIndex(placeWorked => placeWorked.entity == availableShift.entity._id) > -1)
        canApply = true; 
      
      return {...availableShift, canApply:canApply};
    });
    
    const expertiseSkills = await this.skillsService.getListOfAllSkills({
      industriesToInclude: [expertise], 
      outputFormat: 'names'
    }) as string[];
    
    availableShiftsData.results.forEach((availableShift, i) => {
      if (expertiseSkills.includes(availableShift.skill)) {
        availableShiftsData.results.splice(i, 1);
        availableShiftsData.results.unshift(availableShift);
      }
    });

    return availableShiftsData;
  }

  async isTrainingRequired(userId: string, jobItem) {
      
      let placesWorkedData = await this.getPlacesWorkedForStaffer(userId)

      jobItem.canApply = false;
      
      if(jobItem.isTrainingShift || !jobItem.trainingRequired || placesWorkedData.findIndex(placeWorked => placeWorked.entity == jobItem.entity._id) > -1)
        jobItem.canApply = true; 
      
      return jobItem.canApply;
  }

  public getAppliedShifts() {
    return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/available-shifts?showRecurringOnly=${false}&showMyApplications=${true}`);
  }

  public unApplyFromBroadcast(id: string, staffer: IUser, unApply: boolean, isRecurring: boolean, dueToRCMPFee = false) {
    let path: string;
    let payload;
    if (isRecurring) {
      path = `/api/recurringShifts/${id}/unApplyFromBroadcast`;
      payload = {
        stafferId: staffer._id,
        stafferFirstName: staffer.firstName,
        stafferLastName: staffer.lastName,
        shiftUnapplied: unApply
      };
    } else {
      path = `/api/jobs/${id}/unApplyFromShift`;
      payload = {
        staffyId: staffer._id,
        staffyFirstName: staffer.firstName,
        staffyLastName: staffer.lastName,
        shiftUnapplied: unApply
      };
    }
    if (dueToRCMPFee) {
      path = `${path}?dueToRCMPFee=true`;
    }

    return this.httpPromiseService.httpPutRequest(path, { ...payload });
  }

  public rejectBroadcastShift(jodId: string, stafferId: string, stafferFirstName?: string, stafferLastName?: string) {
    return this.httpPromiseService.httpPutRequest(
      `/api/jobs/${jodId}/rejectShift`, { staffyId: stafferId, stafferFirstName, stafferLastName }
    );
  }

  public rejectRecurringShift(jodId: string, stafferId: string, stafferFirstName?: string, stafferLastName?: string) {
    return this.httpPromiseService.httpPutRequest(
      `/api/recurringShifts/${jodId}/rejectShift`, { stafferId, stafferFirstName, stafferLastName }
    );
  }

  public recurringShiftConfirm(groupShiftId: any, stafferId: string) {
    if(typeof groupShiftId == "object"){
      groupShiftId = groupShiftId._id
    }
    return this.httpPromiseService.httpPutRequest(`/api/jobs/recurringShifts/confirmRecurringJob/${groupShiftId}/${stafferId}`, {});
  }
  public nonRecurringShiftConfirm(jobId: string, stafferId: string) {
    return this.httpPromiseService.httpPutRequest(`/api/jobs/shifts/confirmJob/${jobId}/${stafferId}`, {});
  }

  public acceptBroadcastRequestNonRecurring(jobId: string, stafferId: string, acceptedRcmpFee: boolean) {
    return this.httpPromiseService.httpPutRequest(`/api/jobs/${jobId}/acceptBroadcastRequest?acceptedRcmpFee=${acceptedRcmpFee}`, {
      stafferId
    });
  }

  public acceptNonRecurringHireRequest(
    jobId: string,
    body: {
      stafferId: string;
      shiftResponseTime: number;
    }
  ) {
    return this.httpPromiseService.httpPutRequest(`/api/jobs/${jobId}/acceptHireRequest`, body);
  }

  public rejectNonRecurringJob(job: ITalentJob) {
    return this.httpPromiseService.httpPutRequest(`/api/jobs/${job._id}/reject`, job);
  }

  public rejectStafferBroadcastRequest(body: { jobId: string; stafferId: string; notificationId?: string }) {
    return this.httpPromiseService.httpPutRequest(`/api/jobs/rejectStaffer`, body);
  }

  public hireStafferOnRecurringJob(
    groupJobId: string,
    body: {
      stafferId: string;
    }
  ) {
    return this.httpPromiseService.httpPutRequest(`/api/recurringShifts/${groupJobId}/hireStaffer`, body);
  }

  public fetchJobsForCompanyToRateStaffers() {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/fetchJobsForCompanyToRateStaffers?skip=0`);
  }

  public rateStafferInJobs(body: { stafferId: string; rating: number; jobIds: string[]; skill?: string }) {
    return this.httpPromiseService.httpPutRequest(`/api/jobs/rateStafferInJobs`, body);
  }
  public getEmploymentHistory(userId: string, skip: number) {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/workHistory/${userId}?toSkipItems=${skip}&pageSize=10`);
  }

  public fetchJobsForStafferToRateCompany() {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/fetchJobsForStafferToRateCompany`);
  }

  public rateYourColleague(userId: string) {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/rateYourColleague/fetchJobsToRateYourColleague/${userId}`);
  }

  public rateCompanyInJob(jobId: string, rating: number) {
    return this.httpPromiseService.httpPutRequest(`/api/jobs/${jobId}/rateCompanyInJob`, { rating });
  }
  public rateStaffer(
    jobId: string,
    userId: string,
    body: {
      ratedToStaffer: string;
      rating: number;
    }
  ) {
    return this.httpPromiseService.httpPostRequest(`/api/jobs/rateYourColleague/rate/${jobId}/${userId}`, body);
  }
  public fileDispute(
    jobId: string,
    body: {
      staffyId: string;
      message: string;
    }
  ) {
    return this.httpPromiseService.httpPostRequest(`/api/jobs/${jobId}/fileDispute`, body);
  }

  public confirmLoggedHoursOnAShift(
    stafferId: string,
    body: {
      startAndEndTime: {
        start: number;
        end: number;
      };
      jobId: string;
      timeZone: string;
    }
  ) {
    return this.httpPromiseService.httpPatchRequest(`/api/tracker/confirmLoggedHours/${stafferId}`, body);
  }

  public editManualLoggedHours(
    stafferId: string,
    body: {
      startAndEndTime: {
        start: number;
        end: number;
      };
      jobId: string;
      timeZone: string;
    }
  ) {
    return this.httpPromiseService.httpPutRequest(`/api/manualHoursLogged/requestFromStaffer/update/${stafferId}`, body);
  }

  public sendRequestToAdminToLoggedHours(
    stafferId: string,
    body: {
      startAndEndTime: {
        start: number;
        end: number;
      };
      jobId: string;
      timeZone: string;
    }
  ) {
    return this.httpPromiseService.httpPostRequest(`/api/manualHoursLogged/requestFromStaffer/${stafferId}`, body);
  }

  public fetchStaffersJobToShowWorkedPlaces() {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/fetchStaffersJobToShowWorkedPlaces`);
  }

  public fetchStaffersPreviousPlacesWorked(stafferId) {
    return this.httpPromiseService.httpGetRequest(`/api/placesWorked?staffer=${stafferId}&populateCreator=true`);
  }

  public getOpenShiftsRequest(jobId = '', onlyRecurrentJobs: any) {
    return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/client-shifts?jobId=${jobId}&onlyRecurrentJobs=${onlyRecurrentJobs}&preset=getUnfilledJobs&withUnitWorked=true`);
  }

  public getOngoingShiftsRequest() {
    return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/client-shifts?preset=getOngoingJobs`);
  }

  public upcomingStaffersGroupedByShiftDate() {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/upcomingStaffersGroupedByShiftDate`);
  }

  public getFilledShiftsRequest(onlyRecurrentJobs: (boolean | string)) {
    return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/client-shifts?onlyRecurrentJobs=${onlyRecurrentJobs}&preset=getFilledJobs`);
  }

  public fetchPastShiftsGroupedByShiftDate(startDate: string,endDate: string) {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/pastShiftsGroupedByShiftDate?startDate=${startDate}&endDate=${endDate}`);
  }

  public deleteNonRecurrShift(shiftId: string) {
    return this.httpPromiseService.httpDeleteRequest(`/api/jobs/deleteShift/${shiftId}`);
  }

  public deleteShiftWithIn48Hours(shiftId: string) {
    return this.httpPromiseService.httpPostRequest(`/api/jobs/deleteShiftWithIn48Hours/${shiftId}`, { deleteShift: true });
  }

  public deleteRecurrShift(shiftId: string, toDelete, body?:{
    specificJobs : Object,
    cancellationReason : string
  }) {
    return this.httpPromiseService.httpPostRequest(`/api/recurringShifts/admin/deleteShift/${shiftId}?toDelete=${toDelete}`, body || {});
  }

  public rebroadcastShiftAndUpdateRate(jobId: string, updatedRate: number) {
    return this.httpPromiseService.httpPutRequestForCompany(`/api/jobs/rebroadcastShift/${jobId}`, {
      everyone: true,
      updatedRate
    });
  }

  public rebroadcastRecurringShiftAndUpdateRate(jobId: string, updatedRate: number) {
    return this.httpPromiseService.httpPutRequestForCompany(`/api/recurringShifts/rebroadcastShift/${jobId}`, {
      everyone: true,
      updatedRate
    });
  }

  public rejectStafferRecurringRequest(
    groupJobId: string,
    body: {
      stafferId: string;
      notificationId?: string;
    }
  ) {
    return this.httpPromiseService.httpPutRequest(`/api/recurringShifts/${groupJobId}/rejectBroadcastRequest`, body);
  }
  public deleteUpcomingRecurringShifts(jobId: string, forcefully: boolean) {
    const payload: { deleteShift?: boolean } = {};
    if (forcefully) {
      payload.deleteShift = true;
    }
    return this.httpPromiseService.httpPostRequest(`/api/recurringShifts/admin/deleteUpcomingShifts/${jobId}`, payload);
  }

  public retryPay(invoiceId: string) {
    return this.httpPromiseService.httpPostRequest(`/api/jobs/automateTransaction/${invoiceId}?actionTaken=employer`, {});
  }

  public fetchPreviousHiredStaffys(skip: number) {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/previousStaffysEmp?skip=${skip}`);
  }

  public fileMultipleDispute(
    id: string,
    data: {
      disputedJobs: {
        jobId: string;
        stafferId: string;
      }[];
      dispute: string;
    }
  ) {
    return this.httpPromiseService.httpPostRequest(`/api/jobs/${id}/fileMultipleDispute`, data);
  }

  public broadcastRequest(data: IBroadcastRequest) {
    return this.httpPromiseService.httpPostRequestForCompany(`/api/jobs/broadcastRequest`, data, 'text');
  }

  public createShiftRequest(data: IBroadcastRequest) {
    return this.httpPromiseService.httpPostRequestForCompany(`/api/jobs/createShiftRequest`, data, 'text');
  }

  public recurringShiftsBroadcastRequest(data) {
    return this.httpPromiseService.httpPostRequestForCompany(`/api/recurringShifts/broadcastRequest`, data, 'text');
  }

  public recurringShiftsDirectHireRequest(params) {
    return this.httpPromiseService.httpGetRequestForCompany(`/api/recurringShifts/shift/searchStaffers?${params}`);
  }

  public createMutipleDaysShiftWithDifferentSkills(data) {
    return this.httpPromiseService.httpPostRequestForCompany(`/api/jobs/createMutipleDaysShiftWithDifferentSkills`, data, 'text');
  }

  public createDirectHireShiftRequest(data) {
    return this.httpPromiseService.httpPostRequestForCompany(`/api/recurringShifts/shift/sendHireRequest`, data);
  }

  public updateCOVIDAssessmentForStaffers(data: { stafferId: string; covid: any; jobIds: Array<string>; covidAssessment: boolean }) {
    return this.httpPromiseService.httpPutRequest(`/api/jobs/updateCOVIDAssessmentForStaffers`, { data });
  }

  public searchStaffersForJob(entityId, queryParam) {
    return this.httpPromiseService.httpGetRequestForCompany(`/api/jobs/${entityId}/searchStaffersForJob?${queryParam}`);
  }

  public editShift(jobId, body) {
    return this.httpPromiseService.httpPutRequestForCompany(`/api/jobs/editShift/${jobId}`, body);
  }

  public editShiftRecurring(groupShiftId, body) {
    return this.httpPromiseService.httpPutRequestForCompany(`/api/recurringShifts/admin/editShift/${groupShiftId}`, body);
  }

  public editShiftUnitNumber(body, jobId: string) {
    return this.httpPromiseService.httpPutRequestForCompany(`/api/jobs/editShiftUnitNumber/job`, body);
  }

  public rebroadcastShift(jobId, options, jobType) {
    let url = '';

    if (jobType === 'nonRecurring') {
      url = '/api/jobs/rebroadcastShift/' + jobId;
    } else {
      url = '/api/recurringShifts/rebroadcastShift/' + jobId;
    }

    return this.httpPromiseService.httpPutRequestForCompany(url, options);
  }

  public getOpenRequestCount() {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/openRequest/count?newAppUpdated=true`);
  }

  public getFilledRequestCount() {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/closedRequest/count?newAppUpdated=true`);
  }

  public findUpcomingShifts(userId: string, province: string, onlyForStore: boolean, withDeleted:Boolean=false) {
    return this.httpPromiseService.httpGetRequest(
      `/api/jobs/findUpcomingShiftsForLimitedDays/${userId}/${province}?storeShifts=${onlyForStore}&withDeleted=${withDeleted}`
    );
  }

  public getPendingConfirmationCount(){
    return this.httpPromiseService.httpGetRequest(
      `/api/jobs/pendingConfirmationCount/`
    );
  }

  public findPreviousShifts(userId: string, province: string) {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/findPreviousShifts/${userId}/${province}`);
  }

  public ensureLocation(userId: string, jobId: string, loc) {
    return this.httpPromiseService.httpPostRequest(`/api/jobs/ensureLocation/${userId}/${jobId}`, loc);
  }

  public logDisclaimer(data) {
    return this.httpPromiseService.httpPostRequest(`/api/jobs/disclaimer`, data);
  }

  public findShift(id: string) {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/fetchShiftDetails/${id}`);
  }

  public fetchContractorHistoryWithClient(stafferId: string): Promise<IContractorShiftStats> {
    return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/stats/contractor-work-history?stafferId=${stafferId}`);
  }

  public getShiftHistoryOfContractor(stafferId: string, showPastShifts: boolean): Promise<Array<IContractorHistory>> {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/contractor-history/${stafferId}?showPastShifts=${showPastShifts}`);
  }

  async viewShiftTimings(jobItem: ITalentJob) {
    const myModal = await this.modalController.create({
      component: RecurringShiftsTimingComponent,
      backdropDismiss: false,
      cssClass: 'wideModal',
      componentProps: {
        jobItem
      }
    });
    return await myModal.present();
  }

  isApplied(userId, job) {
    for (let i = 0; i < job.isABroadcastRequest.interestedStaffers.length; i++) {
      if (job.isABroadcastRequest.interestedStaffers[i] === userId) {
        job.isApplied = true;
        break;
      }
    }
  }

  public getTeams(entityId: string) {
    return this.httpPromiseService.httpGetRequest(
        `/api/teams/allTeams/${entityId}`,
    );
}

  public updateTeam(
    teamId: string,
    data: {
        teamStaffers: string[],
    }
) {
    return this.httpPromiseService.httpPatchRequest(
        `/api/teams/updateTeam/${teamId}`, data
    );
}
hasStafferExistInTheTeam(team, stafferId: string) {
  let alreadyExist = false;
  for (const teamStaffer of team.staffers) {
    if (teamStaffer._id === stafferId) {
      alreadyExist = true;
      break;
    }
  }
  return alreadyExist;
}

  public addRecurrentShiftTemplate(payload: any) {
    return this.httpPromiseService.httpPostRequestForCompany(`/api/recurrentShiftTemplate/`, payload);
  }

  public deleteRecurrentShiftTemplate(id: string) {
    return this.httpPromiseService.httpDeleteRequestForCompany(`/api/recurrentShiftTemplate/${id}`);
  }
   
  public async viewCareNotes(id: string) {
    return this.httpPromiseService.httpGetRequest(`/api/jobs/${id}/care-notes`);
  }

  public async viewCareNotesV2(id: string, patientId?: string) {
    const queryParams = patientId ? `?patientId=${patientId}` : '';
    return this.httpPromiseService.httpGetRequest(`/api/v2/jobs/${id}/care-notes${queryParams}`);
  }

  public createIndividualShiftsRequest(data) {
    return this.httpPromiseService.httpPostRequest(`/api/jobs/create-individual-shifts`, data, 'text');
  }
  
  public getRecurringShiftsV2(queryParams) {
    return this.httpPromiseService.httpGetRequest(
      `/api/v2/jobs?${new URLSearchParams(queryParams).toString()}`
    );
  }

  public editRecurrentShiftTempalte(id:string,payload: any) {
    return this.httpPromiseService.httpPutRequest(`/api/recurrentShiftTemplate/${id}`, payload);
  }

  public editRecurrentSpecificShifts(groupJobId: string, payload: any) {
    return this.httpPromiseService.httpPutRequest(`/api/recurringShifts/bulkEdit/${groupJobId}`, payload);
  }
}