import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { IClientShift } from 'src/modules/company-dashboard/interfaces/clientShift.interface';
import { UtilService } from './util.services';
import { IResourceData } from 'src/modules/company-dashboard/interfaces/resourceData.interface';
import { IHiredContractors } from 'src/modules/company-dashboard/interfaces/hiredStaffers.interface';
import { IJobState } from 'src/modules/company-dashboard/interfaces/job-state.interface';
import { IRecurrentTemplate } from 'src/modules/company-dashboard/interfaces/recurrent-template.interface';
import { IUser } from 'src/modules/authentication/interfaces/user.interface';
import { EventInput } from '@fullcalendar/core';
import { ICalendarEvent } from '../interfaces/calendar-event.interface';

@Injectable({ providedIn: 'root' })

export class CalendarFormattingService {
  
  constructor(
    private utilService: UtilService) { }

    sortResourcesChronologically(resourceData): moment.Moment[] {
        //In date column, sort the column by resources (dates of week) and not by alphabets
        return resourceData.sort(function(a, b) {
          var momentA = moment(a.title, 'DD ddd');
          var momentB = moment(b.title, 'DD ddd');
          return momentA.isBefore(momentB) ? -1 : 1;
       });
    }

    isCurrentDateWithinRange(startTime: string, endTime: string): boolean {
        const currentDate = moment();
        return currentDate.isBetween(moment(startTime, 'X'), moment(endTime, 'X'), 'day', '[]');
    }

    //Handle view when popup option is updated and update the number of staffers shown on the list 
    handleCurrentView(selectedView: string): [string, number] {  
      const calendarDurationOptions = {
        'day': ['resourceTimelineDay', 7],
        'week': ['resourceTimelineWeek', 7],
        'month': ['dayGridMonth', 3]
      }

      return calendarDurationOptions[selectedView] || ['dayGridMonth', 3]      
    }

    // Function to create the title
    createCalendarTitle(skill: string, hiredStaffers: Array<any>): string {
        const stafferNames = hiredStaffers.map(item => `${item.firstName} ${item.lastName}`).join(',');
        return `(${this.utilService.getSkillAbbreviation(skill)}) \n ${stafferNames}`;
    }

    // Function to format start and end times
    // Function to format start and end times
    formatCalendarTime(currentView: string, time: number, timeZone: string): string {
        if(currentView !== 'resourceTimelineWeek') {
          return moment.unix(time).tz(timeZone).format('YYYY-MM-DDTHH:mm:ss');;
        }
         return moment.unix(time).tz(timeZone).format('HH:mm:ss');
    }

    setCalendarTitle(currentDate: moment.Moment, currentView: string, timezone: string, resourceData: IResourceData[]): string {
        const dateByView = {
          resourceTimelineDay:  moment(currentDate).tz(timezone).format('MMMM DD, YYYY'),
          resourceTimelineWeek: this.setWeeklyCustomTitle(resourceData, currentDate, timezone) ,
          dayGridMonth: moment(currentDate).tz(timezone).startOf('month').format('MMMM YYYY')
        }
        return dateByView[currentView];
    }

    setWeeklyCustomTitle(resourceData: IResourceData[], currentDate: moment.Moment, timezone: string): string {

    if(!resourceData || !resourceData.length) {
        return moment(currentDate).tz(timezone).format('MMMM, YYYY');
    }

    const startRange = moment(resourceData[0].momentFormat, 'MMM DD, YYYY').tz(timezone)
    const endRange = moment(resourceData[resourceData.length - 1].momentFormat, 'MMM DD, YYYY').tz(timezone);

    if(startRange.format('MMM') === endRange.format('MMM')) {
        return startRange.format('MMM D') + '-' + endRange.format('D, YYYY');
    }

    return startRange.format('MMM D') + '-' + endRange.format('MMM D, YYYY');
    }

    formatStaffersList(hiredStaffers: (IHiredContractors| IUser)[]): [string, number] {
        let truncatedStafferNames = '';
        let remainingCount = 0;

        //Return empl
        if(!hiredStaffers || !hiredStaffers.length) {
            return [truncatedStafferNames, remainingCount];
        }

        //Fetch contractor first name and last name
        const stafferNames = hiredStaffers.map(item => `${item.firstName} ${item.lastName}`);
      
        //If the array is less than 7, show all contractors name in a list, separated by comma and ending with &
        if(stafferNames.length < 7) { 
            truncatedStafferNames = stafferNames.length > 1 ? stafferNames.splice(0, stafferNames.length - 1).join(',') + ' & ' + stafferNames.splice(stafferNames.length - 1, stafferNames.length) : stafferNames[0];
        } else{
            //If the array is more than 7, Show first 7 names and add the remaing count.
            //Remaining count is the number of total contractors - 7. Which is the count to 'see more'
            //For example name1, name 2....name 7 & 13 more 
            truncatedStafferNames = stafferNames.splice(0, 7).join(',');
            remainingCount = ((hiredStaffers.length - 7));
        }
      return [truncatedStafferNames, remainingCount];
    }

    //Evaluate job status: open, filled, completed or partial
    evaluateJobStatus(jobObj?: IClientShift, isOngoingShift?: boolean): string {
        
        if(isOngoingShift && jobObj.hiredStaffers && jobObj.hiredStaffers.length) {
          return 'ongoing';
        } 

        if(jobObj.hiredStaffers && jobObj.hiredStaffers.length && jobObj['completedStaffersCount'] > 0) {
          return 'completed';
        }
  
        if(!jobObj.isFilled && jobObj.hiredStaffers && jobObj.hiredStaffers.length) {
          return 'partial';
        }
  
  
        if(!jobObj.isFilled && (!jobObj.hiredStaffers || !jobObj.hiredStaffers.length)) {
          return 'open';
        }
  
        if(jobObj.isFilled) {
          return 'filled';
        }
      }

    renderStafferList(staffers: [string, number], showHyphen?: boolean): string {
      //Return if no contractor found
      if(!staffers || !staffers[0]) {
          return '';
      }

      let stafferList = showHyphen ? ` - ${staffers[0]}` : `${staffers[0]}`;
     
      //If the remaining staffer count is greater than 0, show the list of hidden staffers
      //When list exceeds 7 or more staffers 

      if(staffers[1] > 0) {
        stafferList += ` and <span class = "clickable-staffer-list" (click) = ${this.showRemainingStaffers(staffers)}>${staffers[1]} more </span>`
      }

      return stafferList;
    }

    showRemainingStaffers(staffers: [string, number]) {
      return '';
    }

    eventStyleByJobStatus(jobStatus: string, isPreviousShift: boolean): string {
      // Define color variables for different job statuses
      const colorVariables = {
        'ongoing' : {
          className: 'completedEvent completed-shift-event'
        }, 
        'filled': {
          className: 'filledEvent filled-shift-event'
        },
        'open': {
          className: 'open-shift-event'
        } , 
        'completed': {
          className: 'completedEvent completed-shift-event'
      },  
      'partial': {
          className: 'partial-shift-event'
        }  
      }

      // Get the variable names for the current job status
      if(jobStatus === 'ongoing') {
        return colorVariables['ongoing'].className;
      }


      // Get the variable names for the current job status
      if(!isPreviousShift) {
        return colorVariables[jobStatus].className;
      }

      colorVariables[jobStatus].className = colorVariables[jobStatus].className + ' disabled';
      return colorVariables[jobStatus].className;

  }

  eventIconsByJobStatus(jobStatus: string): string {
    const popupIcons = {
      'open': 'fas fa-door-open icon',
      'filled': 'fas fa-user-circle icon',
      'partial': 'fas fa-user-circle icon',
      'completed': 'fas fa-check-circle icon',
      'ongoing': 'fas fa-bullseye icon'

    }

    return popupIcons[jobStatus];
  }

  fetchNavigationURLPath(jobStatus: string, isShiftFilled: boolean): string {
    //By default shift naviagtion path is set open shift requests
    let url = 'company-dashboard/shift-requests/open/all';

    //Since ongoing priority is higher than any other job type, navigate to ongoing
    if(jobStatus === 'ongoing') { 
      url = 'company-dashboard/shift-requests/ongoing-shifts/all';
    }


    //Otherwise navigate to filled shift required for filled/partially filled shifts
    if(isShiftFilled && jobStatus !== 'ongoing') { 
     url = 'company-dashboard/shift-requests/filled/all';
    }

    return url;
  }

  //Navigate to shift view by job status
  navigationPathUrlByState(jobStatus: string, isShiftFilled: boolean): {url: string, icon: string} {
    const navigateUrl = this.fetchNavigationURLPath(jobStatus, isShiftFilled); 

    const navigatePathObject = {
      open: {url: navigateUrl, icon: 'solid-circle light-bg basicDarkColor open-border fa fa-arrow-right'},
      filled: {url: navigateUrl, icon: 'solid-circle light-bg basicDarkColor fa fa-arrow-right'},
      partial: {url: navigateUrl, icon: 'solid-circle light-bg basicDarkColor fa fa-arrow-right'},
      completed: {url: navigateUrl, icon: 'solid-circle light-bg basicDarkColor fa fa-check'},
      ongoing: {url: navigateUrl, icon: 'solid-circle light-bg basicDarkColor fa fa-arrow-right'}
    };

    return navigatePathObject[jobStatus];
  }

  navigateButton(jobStatus: string, isShiftFilled: boolean): string {
    const navigatePathParams = this.navigationPathUrlByState(jobStatus, isShiftFilled);
    return `<div (click) = "${navigatePathParams.url}" class = "${navigatePathParams.icon}"></div>`
  }


  //Navigate to screen when see details is clicked
  viewDetailsButton(jobStatus: string, isShiftFilled: boolean): string {
    return `<div class = "button-outline">See details</div>`;
  } 

  renderStafferImage(jobStatus: string, staffers: IHiredContractors[], showOnlyOneImage?: boolean): string {
    if(jobStatus === 'open' && (!staffers || !staffers.length)) {
        // Return a default circle when no staffers are provided
        return `<div class = "dotted-circle dotted-blue-border light-font"></div>`
    } 

    if(jobStatus === 'partial' && (staffers || staffers.length)) {
      // Return a default circle when no staffers are provided
      const template = `<div class = "image-slider">
        ${this.staffersImageCard(staffers, showOnlyOneImage)}
        <div class = "dotted-circle dotted-blue-border light-font"></div>
      </div>`
      return template
    } 
    // Generate the HTML for the image slider using the staffersImageCard function
    const template = `<div class = "image-slider">${this.staffersImageCard(staffers, showOnlyOneImage)}</div>`
    return template;
  }

  staffersImageCard(staffers: IHiredContractors[], showOnlyOneImage?: boolean): string {
    var contractorImages = [];
  
    staffers.forEach((attendant) => { 
        // Check if the contractor has a profile URL
        if (!attendant.profileUrl) {
            return; // Skip contractors without a profile URL
        }

        // Generate HTML for each contractor's image
        contractorImages.push(`
            <div class = "img-container">
                <div class = "img-inner">
                <img src = "${attendant.profileUrl}"/>
                </div>
            </div>`)  
    });

    // Join the HTML markup for all contractors and return it as a string
    const numberOfImages = showOnlyOneImage ? 1 : 7;
    return contractorImages.slice(0,numberOfImages).join('');
  }

  addRecurringShiftBadge(groupShift: {recurrentShiftTemplateId: IRecurrentTemplate}): string {

    if(!groupShift || !groupShift.recurrentShiftTemplateId) {
      return '';
    }

    return `
      <div class = "recurrent">
        <div class = "recurrent-icon">
          <img src = "assets/images/repeat.png">
        </div>
      </div>
    `
  }

  addOngoingShiftBadge(jobStatus: string): string {
    if(jobStatus !== 'ongoing') {
      return '';
    }

    return `
      <div class = "recurrent">
        <div class = "recurrent-icon">
          <i class = "fa fa-bullseye"></i>
        </div>
      </div>
    `
  }

  //Check if all flags are marked false, If true, Reset the calendar to its inital state and remove all filters
  resetJobStatusFlags(jobStatusFlag: IJobState): boolean {
    const allFlagsFalse = Object.values(jobStatusFlag).every((flag: boolean) => flag === false);
    return allFlagsFalse;
  }

    // Process a single job object to generate event(s)
  processJob(jobObj: IClientShift, defaultTimeZone: string, currentView: string): Array<EventInput> {
    const timeZone = jobObj.timeZone || defaultTimeZone;
    // jobObj['similarTimings'] = this.talentJobFormatService.checkSimilarTimings(jobObj);
  
    jobObj.hiredStaffers = this.fetchHiredStaffers(jobObj);
    const staffers = this.formatStaffersList(jobObj.hiredStaffers);
    const isOngoingShift = this.utilService.isShiftOngoing(jobObj.shiftStartTime, jobObj.shiftEndTime, jobObj.timeZone);
    const jobStatus = this.evaluateJobStatus(jobObj, isOngoingShift);
    const start = this.formatCalendarTime(currentView, jobObj.shiftStartTime, timeZone);
    const end = this.formatCalendarTime(currentView, jobObj.shiftEndTime, timeZone);
  
    // Check if the job has recurring shifts and format them
    if (jobObj.recurringShift.isTrue && jobObj.recurringShift['groupShift'] && jobObj.recurringShift['groupShift'].recurrentShiftTemplateId) {
      jobObj['formatted'] = this.utilService.formatRecurrentShiftDates(jobObj.recurringShift['groupShift'].recurrentShiftTemplateId);
    }
  
    let eventTimeObject = {
      start,
      end,
      jobObj,
      staffers,
      jobStatus,
      timeZone, 
      currentView,
      isOngoingShift
    }

        
    // Check if the shift starts and ends on the same day or if shift ends at exactly 12:00 am, create single event
    // This will not split the shift by midnight
    if (moment.unix(jobObj.shiftStartTime).isSame(moment.unix(jobObj.shiftEndTime), 'day') ||
      (currentView !== 'resourceTimelineWeek' && moment.unix(jobObj.shiftEndTime).format('HH:mm:ss') === '00:00:00')) {
      eventTimeObject['resourceUnix'] = jobObj.shiftStartTime;
    
      return [this.createEvent(eventTimeObject)];
    } else {
      // Check if the shift starts and ends on the same day
      return this.splitOvernightEvent(eventTimeObject);
    }
  }

  
  // Split an overnight event into two separate events
  splitOvernightEvent(eventTimeObject: ICalendarEvent ): Array<EventInput> {
  
    const { start, end, jobObj, staffers, jobStatus, timeZone, currentView } = eventTimeObject;

    // Calculate the Unix timestamps for midnight and the next day
    const midnightUnix = +moment.unix(jobObj.shiftStartTime).endOf('day').format('X');
    const nextDayUnix = +moment.unix(jobObj.shiftStartTime).add(1, 'day').startOf('day').format('X');

    const formattedMidnight = this.formatCalendarTime(currentView, midnightUnix, timeZone);
    const formattedNextDay = this.formatCalendarTime(currentView, nextDayUnix, timeZone);

    // Create two events: one for the time before midnight and one for the time after
    return [
      this.createEvent({start, end: formattedMidnight, jobObj, staffers, jobStatus, timeZone, currentView, resourceUnix: jobObj.shiftStartTime}),
      this.createEvent({start: formattedNextDay, end, jobObj, staffers, jobStatus, timeZone: timeZone, currentView, resourceUnix: nextDayUnix})
    ];
  }

    // Create an event object
    createEvent(eventTimeObject: ICalendarEvent ): EventInput {
      
      const {start, end, jobObj, staffers, jobStatus, timeZone, currentView, resourceUnix, isOngoingShift} = eventTimeObject;
      const isPreviousShift = moment.unix(jobObj.shiftEndTime).tz(timeZone).isBefore(moment().tz(timeZone));
  
      const eventObject: EventInput = {
        id: moment.unix(resourceUnix).format('DD  ddd'),
        resourceId: moment.unix(resourceUnix).tz(timeZone).format('DD  ddd'),
        start,
        end,
        staffers,
        extendedProps: jobObj,
        startTime: start,
        endTime: end,
        display: ' ',
        textColor: '#1C1F37',
        isPreviousShift,
        jobStatus,
        isOngoingShift: isOngoingShift
      };
  
      // Customize the event object based on the current view
      if (currentView === 'resourceTimelineWeek') {
        eventObject.startTime = eventObject.start;
        eventObject.endTime = eventObject.end;
        delete eventObject.start;
        delete eventObject.end;
      } else {
        delete eventObject.startTime;
        delete eventObject.endTime;
      }
      return eventObject;
    }
  
    
  fetchHiredStaffers(jobObj: IClientShift): IHiredContractors[] {
    const hiredStaffers: IHiredContractors[] = jobObj.hiredStaffers.map(user => ({
      _id: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      profileUrl: user.profileUrl || null,
      clockInStatus: this.getContractorClockInStatus(user)
    }));

    return hiredStaffers;
  }

  getContractorClockInStatus(hiredStaffer): string {

    if(!hiredStaffer || !hiredStaffer.tracker) {
      return; 
    }

      const tracker = hiredStaffer.tracker

      //Contractor has clocked out
      if(tracker && tracker.start && tracker.end){
        return 'clocked-out';
      }

      //Contractor has clocked in
      if(tracker && tracker.start){
        return 'clocked-in';
      }
  }
}