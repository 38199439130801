import { Component, EventEmitter, Input,  OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {

  //options to be displayed on the slider
  @Input() sliderItems: string[];
  // event emitter to emit the selected item
  @Output() onSelect = new EventEmitter();
  // visible items on the slider
  visibleItems: string[] = [];
  // index of the last visible item
  lastVisibleItemIndex: number = 0;
  // index of the selected item
  selectedItemIndex: number;

  ngOnInit() {
    this.updateVisibleItems();
  }

  
  updateVisibleItems() {

    //If no current index found, initialize it to 0
    if(!this.lastVisibleItemIndex) {
      this.lastVisibleItemIndex = 0;
    }

  //   //Load 5 dates on the slider as next or previous is clicked and make the first shift of each group as active
    const startIndex = this.lastVisibleItemIndex;
    // if the remaining items are less than 5, show the remaining items or show the next 5 items 
    const endIndex = Math.min(this.lastVisibleItemIndex + 4, this.sliderItems.length - 1);
   
    this.visibleItems = this.sliderItems.slice(startIndex, endIndex + 1);
  
    //Show first 5 jobs in slider
    if(!this.visibleItems.length) {
      let starting = this.sliderItems.length % 5 === 0 ? this.sliderItems.length - 5 : this.sliderItems.length - this.sliderItems.length % 5;
      this.visibleItems = this.sliderItems.slice(starting, this.sliderItems.length);
    }

    this.activateSliderOption(this.sliderItems[startIndex], 0);
  
  }

  activateSliderOption(selectedItem: string, index: number) {

    this.selectedItemIndex = index;

    //Emit to update active shift by passsing the selected date from slider 
    this.onSelect.emit({
      selectedItem,
    });
  }
  
  previousItem() {
    //Load previous 5 dates on the slider
    // if last visible item index is greater than 0, show the previous 5 items, else do nothing
    if (this.lastVisibleItemIndex) {
      this.lastVisibleItemIndex -= 5;
      //Update view each time previous is clicked
      this.updateVisibleItems();
    }
  }

  nextItem() {
    //Load next 5 dates on the slider
    if (this.lastVisibleItemIndex <= this.sliderItems.length - 1) {
      this.lastVisibleItemIndex += 5;
      //Update view each time next is clicked
      this.updateVisibleItems();
    }
  }
 }
